@import 'bootstrap.css';
@import './login';

@small-screen: 567px;
@medium-screen: 768px;
@large-screen: 992px;
@extra-large-screen: 1280px;
@super-large-screen: 1600px;
@color-white: #ffffff;
@color-gray-light: #f8f9fa;

.w {
  &-60 {
    width: 60% !important;
  }
}
.text {
  &-decoration-none {
    text-decoration: none !important;

    &:hover,
    &:focus {
      text-decoration: none !important;
    }
  }
}

.cbn {
  &-header {
    background: @color-gray-light;
    @media (max-width: @medium-screen) {
      padding: 0 5px;
    }

    &-menu {
      white-space: nowrap !important;
      background: @color-gray-light;
      border-bottom: none;
      &.menu-mobile {
        float: right;
        .ant-menu-submenu-title {
          padding-right: 0;
        }
      }
      @media (max-width: @large-screen) {
        white-space: unset;
        max-width: 50%;
      }
    }
  }

  &-logo {
    padding-right: 16px;
    padding-left: 40px;
    line-height: 64px;
    white-space: nowrap;
    text-decoration: none;
    font-size: 18px;
    overflow: hidden;
    @media (max-width: @small-screen) {
      padding-left: 10px;
    }
    img {
      position: relative;
      top: -1.5px;
      height: 32px;
      margin-right: 16px;
    }
  }

  &-user {
    &.ant-form-horizontal {
      .ant-form-item-label {
        text-align: left;
      }
    }
  }

  &-type-card {
    position: relative;
    height: 100%;
    &.active {
      border: 1px solid #1890ff;
    }
    &.card-disable {
      &:after {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.5);
      }
    }
    .ant-card-head {
      min-height: auto;
      padding: 8px;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      position: relative;

      .ant-card-head-title {
        padding: 0;
      }

      .ant-card-extra {
        position: absolute;
        padding: 0;
        top: 0px;
        right: 0px;
      }
    }

    .ant-card-cover {
      text-align: center;
    }

    .ant-card-body {
      padding: 10px;
    }
  }

  &-type-image {
    height: 160px;
    width: 160px;
    overflow: hidden;
    margin: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  &-card {
    &__category {
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
      //overflow: hidden;
    }
  }

  &-tab {
    &__category {
      .ant-tabs-nav {
        &::before {
          border-bottom: none;
        }
      }

      .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
          flex: 1;
          justify-content: center;
          border-color: #dee2e6 #dee2e6 #fff;
          padding: 10px 16px;
          border-radius: 4px 4px 0 0 !important;

          &:hover,
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.cbn-list-items {
  .ant-list-item {
    border: 1px solid #f0f0f0;
    padding: 10px;
    margin-bottom: 10px;

    &:last-child {
      border: 1px solid #f0f0f0;
    }

    &.active {
      border: 1px solid #1890ff;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.h {
  &-82x {
    max-height: 82px;
    height: 82px;
  }

  &-85x {
    max-height: 85px;
    height: 85px;
  }
  &-134x {
    max-height: 134px;
    height: 134px;
  }
  &-180x {
    max-height: 180px;
    height: 180px;
  }
}

.cbn-style {
  .cbn-type-image {
    width: 100%;
    height: 60px;
  }
}

.preview-title {
  min-height: 38px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.cbn-sidebar {
  width: 150px;
  position: absolute;
  z-index: 1;
  &.sticky {
    top: 20px;
  }
  &-left {
    left: -160px;
  }
  &-right {
    right: -160px;
  }
  .cbn-menu-sidebar {
    border-right: 0px;
    list-style: none;
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0;
    li {
      cursor: pointer;
      padding: 10px 6px;
      &:first-child {
        padding-top: 0;
      }
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
}

@media (min-width: @medium-screen) {
  .w-md-100 {
    width: 100%;
  }
}

@media (min-width: @large-screen) {
  .w-lg-100 {
    width: 100%;
  }
}
@media (max-width: @small-screen) {
  .cbn-sidebar {
    display: none;
  }
  .cbn-type-card {
    margin-bottom: 15px;
  }
}
@media (max-width: @extra-large-screen) {
  .container.content-page {
    //max-width: 960px !important;
    max-width: none !important;
    width: calc(100% - 300px);
  }
  .cbn-type-image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    img {
      position: absolute;
      top: 0px;
      height: auto;
      left: 0px;
      right: 0px;
    }
  }
  .cbn-sidebar {
    width: 150px;
    &-left {
      left: -160px;
    }
    &-right {
      right: -160px;
    }
  }
}

@media (min-width: @extra-large-screen) {
  .w-xl-100 {
    width: 100%;
  }
}

@media (min-width: @extra-large-screen) and (max-width: @super-large-screen) {
  .container.content-page {
    max-width: 1024px !important;
  }
  .cbn-type-image {
    width: 100%;
    padding-top: 100%;
    position: relative;
    img {
      position: absolute;
      top: 0px;
      height: auto;
      left: 0px;
      right: 0px;
    }
  }
  .cbn-sidebar {
    max-width: 170px;
    width: 170px;
    &-left {
      left: -180px;
    }
    &-right {
      right: -180px;
    }
  }
}

.custom-modal-auth {
  // width: auto !important;
}

.cabinet-form {
  &--profile {
    margin-bottom: 15px;
    .ant-form-item-label {
      text-align: left;
    }
  }
}

.cbn-tabs {
  .ant-tabs-tab {
    padding: 0px !important;
    border-radius: 0;
    background: #ffffff !important;

    //&:focus{
    //  border-radius: 0 !important;
    //  border: 0;
    //  box-sizing: unset;
    //  outline-color: transparent;
    //  background: transparent;
    //  color: #1890ff;
    //}
    span {
      display: block;
      padding: 6px 16px;
      &.tab-has-data {
        background: #90ee90;
        color: #1890ff;
      }
      &.tab-active {
        //background: #008000;
        background: #90ee90;
        color: #1890ff;
        border: 1px solid rgba(0, 0, 0, 0.5);
        //border-bottom-width: 0;
      }
    }
  }
}

.ant-back-top {
  right: 3.84rem;
  bottom: 3.84rem;
}

.ant-back-top-content {
  border-radius: 5px;
  background: #d2d9e5;

  &:hover {
    background: #b8beca;
  }
}

.ant-back-top-icon {
  width: 14px;
  height: 16px;
  margin: 12px auto;
  background: url('../img/back-to-top.png') no-repeat;
  background-position-x: 100%;
  background-size: 100%;
}

.divider-preview-not-select {
  .ant-divider-inner-text {
    padding: 0;
    color: rgba(0, 0, 0, 0.06);
  }
}

.style-item {
  transition: height 1s linear;
  display: none;
  opacity: 0;
  &.style-visible {
    opacity: 1;
    display: flex;
  }
}

.cbn-select-props {
  padding: 0;
  min-width: 130px !important;
  .ant-select-item {
    // min-height: 28px;
    // padding: 2px 12px;
    // text-align: center;
    border-bottom: 1px solid #d9d9d9;
  }
}

.max-width-250 {
  max-width: 250px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-12 {
  font-size: 12px;
}

.job {
  &__property {
    label {
      text-align: left;
    }
  }
}

.width-auto {
  width: auto !important;
}

.current-job {
  &__item {
    .ant-form-item-label > label::after {
      content: '';
    }
  }
}

.modal-style {
  &__button {
    top: 20px;
    z-index: 2;
  }

  &__content {
    //width: 810px;
  }
  .ant-modal-body {
    padding: 7px;
  }
}

.card-feature {
  min-height: 180px;
  height: auto;
}
.list-menu {
  li {
    a {
      display: block;
      padding: 10px 7px;
      &:hover {
        color: #1890ff !important;
      }
    }
    &.active {
      a {
        color: #1890ff;
      }
    }
  }
}
.min-width {
  &-17 {
    min-width: 17%;
  }
  &-20 {
    min-width: 20%;
  }
}

.button-new-style {
  white-space: normal !important;
  min-height: 32px;
  height: auto !important;
}

.no-handler-wrap .ant-input-number-handler-wrap {
  display: none;
}

.has-error {
  .ant-select-selector {
    border-color: red !important;
  }

  &.ant-input-number {
    border-color: red !important;
  }
}
